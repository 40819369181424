/* eslint-disable @typescript-eslint/no-explicit-any */
// 这个是同构 fetch，既能在服务端，又能在客户端
import fetch from 'isomorphic-unfetch'
// query 格式化的插件，其实可以自己实现
import qs from 'qs'
// 捕获异常内部处理的一个提示，和你项目用的 ui 库一致就可以
import { ElMessage } from 'element-plus'
import { createPinia } from 'pinia'
const pinia = createPinia()
import { useGlobalStore } from '@/store/index'
const mainStore = useGlobalStore(pinia)
import router from '../router'
// eslint-disable-next-line @typescript-eslint/ban-types
function filterObject(o: Record<string, string>) {
  const res: Record<string, string> = {}
  Object.keys(o).forEach((k) => {
    res[k] = o[k]
  })
  return res
}

export enum EHttpMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

type ICustomRequestError = {
  status: number
  statusText: string
  url: string
  code: number
}

function dealErrToast(err: Error & ICustomRequestError, abortController?: AbortController) {
  console.log(err.code)

  switch (err.code) {
    case 403: {
      abortController && abortController.abort()
      typeof window !== 'undefined' && ElMessage.error(err.statusText)
      break
    }
    case 401: {
      localStorage.removeItem('token')
      router.replace('/')
      break
    }
    default: {
      console.log(err)
      break
    }
  }
}

/**
 * @description: 声明请求头header的类型
 */
interface IHeaderConfig {
  Accept?: string
  'Content-Type'?: string
  [propName: string]: any
}

export interface IResponseData {
  code: number
  data: any
  message: string
  status: number
}

interface IAnyMap {
  [propName: string]: any
}

export interface IRequestOptions {
  headers?: IHeaderConfig
  signal?: AbortSignal
  method?: EHttpMethods
  query?: IAnyMap
  params?: IAnyMap
  data?: IAnyMap
  body?: any
  timeout?: number
  credentials?: 'include' | 'same-origin'
  mode?: 'cors' | 'same-origin'
  cache?: 'no-cache' | 'default' | 'force-cache'
  ossData?: any
}

/**
 * Http request
 * @param url request URL
 * @param options request options
 */
export interface IHttpInterface {
  request<T = IResponseData>(url: string, options?: IRequestOptions, abortController?: AbortController): Promise<T>
}

const CAN_SEND_METHOD = ['POST', 'PUT', 'PATCH', 'DELETE']

class Http implements IHttpInterface {
  public async request<T = IResponseData>(
    url: string,
    options?: IRequestOptions,
    abortController?: AbortController
  ): Promise<T> {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', localStorage.getItem('token') || '')
    const opts: IRequestOptions = Object.assign(
      {
        method: 'GET',
        headers: myHeaders,
        credentials: 'include',
        timeout: 10000,
        mode: 'cors',
        cache: 'no-cache'
      },
      options
    )
    if (url.indexOf('http') < 0) {
      url = mainStore.baseUrl + url
    } else {
      delete opts.headers
    }
    abortController && (opts.signal = abortController.signal)

    if (opts && opts.query) {
      url += `${url.includes('?') ? '&' : '?'}${qs.stringify(filterObject(opts.query))}`
    }

    const canSend = opts && opts.method && CAN_SEND_METHOD.includes(opts.method)

    if (canSend && opts.data) {
      opts.body = JSON.stringify(filterObject(opts.data))
      opts.headers && Reflect.set(opts.headers, 'Content-Type', 'application/json')
    }

    //console.log('Request Opts: ', opts)
    try {
      const res = await Promise.race([
        fetch(url, opts),
        new Promise<any>((_, reject) => {
          setTimeout(() => {
            return reject({ status: 408, statusText: '请求超时，请稍后重试', url })
          }, opts.timeout)
        })
      ])
      const result = await res.json()
      if (result.code !== 0) dealErrToast(result, abortController)
      return result
    } catch (e: any) {
      dealErrToast(e, abortController)
      return e
    }
  }
}

const { request } = new Http()

export { request as default }
