import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/HomeView',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/AdminView.vue'),
    redirect: '/admin/bigScreenInput',
    children: [
      {
        path: 'bigScreenInput',
        name: 'bigScreenInput',
        component: () => import('../views/BigScreenInputView.vue'),
        meta: {
          title: '业绩大屏'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
// 全局路由守卫
router.beforeEach(async (to) => {
  if (
    // 检查用户是否已登录
    !localStorage.getItem('token') &&
    // ❗️ 避免无限重定向
    to.name !== 'login'
  ) {
    // 将用户重定向到登录页面
    return '/'
  }
  if (localStorage.getItem('token') && to.name === 'login') {
    return '/HomeView'
  }
})
export default router
