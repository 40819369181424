import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/style/reset.scss'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
const pinia = createPinia()

createApp(App).use(ElementPlus, { size: 'default', zIndex: 3000, locale: zhCn }).use(pinia).use(router).mount('#app')
import request from '@/utils/fetch'
pinia.use(({ store }) => {
  store.$request = request
  store.$router = router
})
