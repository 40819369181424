// import { createStore } from 'vuex'

// export default createStore({
//   state: {
//     baseUrl: process.env.NODE_ENV === 'development' ? 'http://192.168.10.6:8901/' : ''
//   },
//   getters: {},
//   mutations: {},
//   actions: {},
//   modules: {}
// })
// @ts-check
import { defineStore } from 'pinia'
import { IResponseData } from '@/utils/fetch'
import { ElMessage } from 'element-plus'

interface GlobalState {
  baseUrl: string
}
interface Login {
  username: string
  password: string
}
export const useGlobalStore = defineStore({
  id: 'mian',
  state: (): GlobalState => ({
    /** @type {string[]} */
    baseUrl: process.env.NODE_ENV === 'development' ? 'http://192.168.10.84:9501' : 'https://dataview.waterdp.com'
  }),
  getters: {},
  actions: {
    login(data: Login) {
      this.$request(`/api/dataview_service/web/permission/login/`, {
        method: 'POST',
        data
      })
        .then((res: IResponseData) => {
          if (res.code === 0) {
            localStorage.setItem('token', res.data.token)
            this.$router.push('/HomeView')
          } else {
            ElMessage({
              type: 'error',
              message: res.message
            })
          }
        })
        .catch((err: unknown) => {
          console.log(err)
        })
    }
  }
})
